<template>
  <div class="operate-management-container">
    <div class="operate-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="供应商名称">
          <label>{{ pageData.supplierName || "-" }}</label>
        </el-form-item>
        <el-form-item label="联系人">
          <label>{{ pageData.supplierContract || "-" }}</label>
        </el-form-item>
        <el-form-item label="联系方式">
          <label>{{ pageData.supplierPhone || "-" }}</label>
        </el-form-item>
        <el-form-item label="创建时间">
          <label>{{ pageData.createTime || "-" }}</label>
        </el-form-item>
        <el-form-item label="关联设备">
          <label>{{ pageData.abbreviation || "-" }}</label>
        </el-form-item>
        <el-form-item label="设备总量">
          <label>{{ pageData.number || 0 }}</label>
        </el-form-item>
        <el-row>
          <el-form-item style="float: left; marginright: 0px">
            <el-button type="primary" @click="addAccount()">添加账号</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="operate-table-container">
      <div class="operate-table-content">
        <el-table
          style="width: 100%"
          height="500px"
          v-loading="loading"
          :data="list"
        >
          <el-table-column label="姓名" prop="sn">
            <template slot-scope="scope">
              {{ scope.row.userName || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="联系方式" prop="sender">
            <template slot-scope="scope">
              {{ scope.row.mobile || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="添加时间" prop="status">
            <template slot-scope="scope">
              {{ scope.row.createTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="deleteHandle(scope.row)" type="text"
                >删除</el-button
              >
              <el-button
                @click="setHandle(scope.row)"
                v-if="scope.row.enabled === 0"
                type="text"
                >启用</el-button
              >
              <el-button
                @click="setHandle(scope.row)"
                v-if="scope.row.enabled === 1"
                type="text"
                >停用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="operate-table-page">
        <div class="operate-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <AddAccount v-if="visable" ref="addOrUpdate" @refreshDataList="getData" />
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import AddAccount from "./components/addAccount.vue";
import "./Operation.less"
export default {
  name: "SupplierOperation",
  components: { AddAccount },
  data() {
    return {
      list: [],
      total: 0,
      visable: false,
      loading: false,
      pageData: {},
      formData: {
        id: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        organId: null,
      },
    };
  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.formData.id = this.$route.params.id;
    this.getData();
  },
  methods: {
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/supplier/search`, this.formData)
        .then((res) => {
          const { supplierDto, userInfoList } = res;
          this.pageData = supplierDto;
          this.list = userInfoList.list;
          this.total = userInfoList.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        brand: null,
        curStatus: null,
        model: null,
        ompStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
      };
      this.getData();
    },
    //删除
    deleteHandle(row) {
      this.$confirm(`确认删除操作吗, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post(`/api/supplier/del`, { id: row.id })
          .then((rs) => {
            this.getData();
            return this.$message.success(rs);
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },

    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },

    addAccount() {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init();
        this.$refs.addOrUpdate.title = "新增账号";
        this.$refs.addOrUpdate.dataForm.supplierId = this.formData.id;
      });
    },
    //跳转详情
    setHandle(row) {
      let enabled = null
      if(row.enabled === 1) {
        enabled = 0
      } else {
        enabled = 1
      }
      axios
        .post(`/api/supplier/enableSupplier`, { id: row.id, enabled: enabled })
        .then((rs) => {
          this.getData();
          return this.$message.success(rs);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
  filters: {
    statusStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "已处理";
        } else if (value === 0) {
          return "未处理";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
};
</script>