<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    @close="closeHandle"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item label="用户" :label-width="'100px'" prop="userName">
          <el-input
            v-model="dataForm.userName"
            placeholder="用户"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>

        <el-form-item
          label="手机号"
          :label-width="'100px'"
          prop="mobile"
        >
          <el-input
            v-model="dataForm.mobile"
            placeholder="手机号"
            clearable
            :style="{ width: '315px' }"
						@blur="inputHandle"
          />
        </el-form-item>
        <el-form-item label="所属机构:" :label-width="'100px'" prop="orgId">
          <el-tree-select
            ref="group-select-tree"
            class="group-select-tree"
            v-model="dataForm.orgId"
            :selectParams="selectParams"
            :treeParams="treeParams"
            :style="{ width: '315px' }"
          >
          </el-tree-select>
        </el-form-item>
        <el-form-item label="状态:" prop="enabled" :label-width="'100px'">
          <el-select
            v-model="dataForm.enabled"
            :style="{ width: '315px' }"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="停用" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        supplierId: null,
				userName: null,
				mobile: null,
				orgId: null,
				roleId: 2,
				enabled: null,
				id: null,
				pwd: null,
				userId: null,
        accountType: 1,
      },
      visible: false,
      deviceList: null,
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: "请选择机构归属",
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        "check-strictly": true,
        "default-expand-all": true,
        "expand-on-click-node": false,
        data: [],
        props: {
          children: "childs",
          label: "orgName",
          value: "orgId",
        },
      },
			organId: null
    };
  },
  computed: {
    rules() {
      return {
        userName: [
          { required: true, message: "必填项不能为空", trigger: "bulr" },
        ],
        mobile: [
          { required: true, message: "必填项不能为空", trigger: "bulr" },
					{ validator: this.validatePhoneNumber, trigger: 'blur' }
        ],
        orgId: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        roleId: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
				enabled: [
					{ required: true, message: "必填项不能为空", trigger: "change" },
				]
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.getDeviceList();
				this.getOrgList()
        if (this.dataForm.id) {
          this.getDataInfo();
        }
      });
    },
    closeHandle() {
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        supplierId: null,
				userName: null,
				mobile: null,
				orgId: null,
				roleId: 2,
				enabled: null,
				id: null,
				pwd: null,
				userId: null,
        accountType: 1,
      }
    },
			validatePhoneNumber(rule, value, callback) {
				if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
					callback();
				} else {
					callback(new Error('请输入合法的手机号'));
				}
			},
		async getOrgList() {
			const rs = await axios.get(`/api/org/getOrgTree/${Number(this.organId)}`);
			this.treeParams.data = [rs]
			this.$nextTick(() => {
					this.$refs['group-select-tree'].treeDataUpdateFun(this.treeParams.data);
				});
		},
		inputHandle() {
			this.dataForm.pwd = this.dataForm.mobile.substring(this.dataForm.mobile.length - 6, this.dataForm.mobile.length)
		},
    getDataInfo() {
      axios
        .post(`/api/supplier/supplierDetail`, { id: this.dataForm.id })
        .then((res) => {
          this.dataForm = { ...res };
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    //获取关联设备列表
    getDeviceList() {
      axios
        .post("/api/supplier/list", {})
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/supplier/addUser`, this.dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>